<template>
  <main class="OrdersForm">
    <TheSubHeader
      icon="package-delivery-alt"
      title="Pedido"
      page-title-tag="Orders Form"
      :actions="subHeaderActions"
    >
      <template #afterButtons>
        <BaseButton
          v-if="cancelButtonPermission && !!orderId"
          icon="trash"
          color="primary-base"
          label="Cancelar pedido"
          class="TheSubHeader__button"
          :track="trackSubheaderButtonString('Orders Form', 'Cancel Order')"
          @click="confirmCancelOrder"
        />
        <BaseButton
          icon="chevron-left-alt"
          label="voltar"
          theme="icon-left"
          :track="trackSubheaderButtonString('Orders Form', 'back')"
          @click="$router.push('/orders')"
        />
      </template>
    </TheSubHeader>
    <div class="OrdersForm__content">
      <DynamicForm
        ref="form"
        :style="[customerCss, deliveryWindowCss, collectWindowCss]"
        class="OrdersForm__form"
        :model="form"
        :blueprint="blueprint({
          isEdit: isEdit,
          hasPaymentLink,
          hasValueInvoicePermission,
          docTypeOptions,
          documentType,
          customer,
          transporterPermission,
          transporters,
          hasDeliveryWindow,
          hasCollectWindow,
          attachments,
          isMaster,
          todayParsed
        })"
        :endpoint-errors="endpointErrors"
        @setAddress="setAddress"
        @addProduct="addProduct"
        @removeProduct="removeProduct"
        @resetDocField="resetDocField"
        @submit="onSubmit"
        @beforeSubmit="checkProducts"
        @selectStore="fetchStoreAddress"
        @postFiles="uploadOrdersFiles"
        @removeFile="removeFile"
        @updateDeliveryWindow="update"
      >
        <template v-if="form.phones.length && orderId">
          <label class="OrdersForm__labelphones">Telefones</label>
          <div class="OrdersForm__phones">
            <div v-for="(phone, i) in form.phones" :key="i" class="OrdersForm__phone">
              <InputGroup
                v-model="phone.phone"
                label="Número"
                autocomplete="no"
                :read-only="true"
              />
            </div>
          </div>
        </template>
        <template v-if="form.products.length && !orderId">
          <label class="OrdersForm__labelProducts">Produtos</label>
          <div class="OrdersForm__products" :style="productGrid">
            <div v-for="(product, i) in form.products" :key="i" class="OrdersForm__product">
              <InputGroup
                v-model="product.name"
                :read-only="orderId"
                :is-invalid="productsRules.name"
                class="OrdersForm__productField OrdersForm__productName"
                label="Nome"
                placeholder="Nome"
                autocomplete="no"
              />
              <InputGroup
                v-if="hasShipping"
                v-model="product.quantity"
                :read-only="orderId"
                :is-invalid="productsRules.quantity"
                mask="number"
                class="OrdersForm__productQtd"
                label="Quantidade"
                placeholder="Quantidade"
                autocomplete="no"
                @input="parseMoney"
              />
              <InputGroup
                v-model="product.weight"
                :read-only="orderId"
                :is-invalid="productsRules.weight"
                class="OrdersForm__productWeight"
                label="Peso (kg)"
                placeholder="Peso (kg)"
              />
              <component
                :is="productsSizeByOrderId"
                v-model="product.size"
                :read-only="orderId"
                :is-invalid="productsRules.size"
                :select-options="productsSizes"
                :all="false"
                label="Tamanho"
                placeholder="Selecione"
                class="OrdersForm__productSize"
              />
              <InputGroup
                v-if="hasShipping"
                v-model="product.unity_price"
                :read-only="orderId"
                :is-invalid="productsRules.unity_price"
                class="OrdersForm__productPrice"
                label="Preço Unitário"
                placeholder="Preço Unitário"
                mask="money"
                @input="parseMoney"
              />

              <template v-if="!orderId">
                <BaseButton
                  v-if="i === form.products.length -1"
                  tag="a"
                  class="OrdersForm__productButton"
                  theme="icon-right"
                  label="Adicionar Volume"
                  color="primary-base"
                  icon="add"
                  data-test="add-product"
                  @click="addProduct"
                />
                <BaseButton
                  v-else
                  class="OrdersForm__productButton"
                  theme="icon-right"
                  label="Remover Volume"
                  color="primary-base"
                  icon="remove"
                  data-test="remove-product"
                  @click="removeProduct(i)"
                />
              </template>
            </div>
          </div>
        </template>
        <template v-if="orderPackages.data.content.length && orderId">
          <label class="OrdersForm__labelProducts">
            PACOTES:
          </label>
          <div v-if="form.volume && form.cubed_weight" class="OrdersForm__productsVolume">
            <div
              class="OrdersForm__productsVolume__wrapper"
              :class="{'OrdersForm__productsVolume__wrapper--heavy': form.is_heavy}"
            >
              <Icon
                name="package-volume"
                :color="form.is_heavy ? 'white' : 'primary-base'"
              />
              cubagem total: {{ form.volume }} m³ x {{ form.cubed_weight }} kg
            </div>
          </div>
          <DataTable
            class="OrdersForm__products"
            no-content-text="Nenhum pacote encontrado"
            :header="orderPackages.data.headers"
            :table="orderPackages.data.content"
          />
        </template>

        <div v-if="hasShipping" v-show="fareObj" class="OrdersForm__shipping">
          Valor Total <strong>{{ total | money }}</strong> - Valor do Frete <strong>{{ fare | money }}</strong>
        </div>
      </DynamicForm>
    </div>
    <DialogOrders ref="DialogOrders" @cancel="cancelOrder" />
  </main>
</template>

<script>
import { address, orders } from '@/api'
import { mapActions, mapState } from 'vuex'
import { BaseTreeSelect, BaseButton } from '@/components/atoms'
import { DynamicForm, InputGroup, DataTable } from '@/components/molecules'
import { TheSubHeader } from '@/components/organisms'
import blueprint from '@/views/orders/Form/OrdersForm.blueprint'
import Rules from '@doc88/flux-validator-js'
import { file } from '@/api'
import DialogOrders  from  '@/views/orders/DialogOrders'

export default {
  name: 'OrdersForm',
  components: {
    TheSubHeader,
    DynamicForm,
    InputGroup,
    BaseTreeSelect,
    BaseButton,
    DialogOrders,
    DataTable
  },
  data() {
    return {
      docTypeMask: '',

      //Form
      form: {
        //Order Details
        store_id: null,
        transporter_id: null,
        order_number: '',
        invoice_number: '',
        invoice_value: 0,
        link: '',
        //Custumer
        order_type: false,
        //Order Receiver
        name: '',
        phone: '',
        phones: [],
        email: '',
        docType: {id: 1,name: 'CPF'},
        document: '',
        //Delivery Details
        driver_id: '',
        store_status_id: '',
        created_at: '',
        order_receiver: '',
        order_receiver_doc: '',
        order_comments: '',
        //Collect Address
        collect_zip: '',
        collect_address: '',
        collect_number: '',
        collect_complement: '',
        collect_neighborhood: '',
        collect_city: '',
        collect_state_id: null,
        //Origin Address
        origin_zip: '',
        origin_address: '',
        origin_number: '',
        origin_complement: '',
        origin_neighborhood: '',
        origin_city: '',
        origin_state_id: null,
        //Destiny Address
        destiny_zip: '',
        destiny_address: '',
        destiny_number: '',
        destiny_complement: '',
        destiny_neighborhood: '',
        destiny_city: '',
        destiny_state_id: null,
        //Prducts
        products: [],
        volume: null,
        cubed_weight: null,
        //Delivery Window
        deliveryWindow: [],
        collectWindow: '',
        //Others
        date: '',
        is_heavy: 0
      },

      //Errors
      endpointErrors: {},

      //Checkers
      customer: false,

      validProducts: [],
      productsRules: {
        name: false,
        weight: false,
        size: false,
        quantity: false,
        unity_price: false,
      },
      fareObj: {},
      total: 0,
      fare: 0,
    }
  },
  computed: {
    ...mapState({
      productsSizes: state => state.orders.productsSizes,
      transporters: state => state.transporters.transporters,
      permissions: state => state.auth.permissions,
      attachments: state => state.file.attachments,
      orderPackages: state => state.orders.orderPackages
    }),

    transportersOptions() {
      // return this.orderId ? this.form.transporter : this.transporters
      return this.transporters
    },

    productsSizeByOrderId() {
      return this.orderId ? 'InputGroup': 'BaseTreeSelect'
    },

    transporterPermission(){
      return this.permissions ? this.permissions?.ordersAdd?.transporter : true
    },

    cancelButtonPermission(){
      return !!this.permissions?.ordersDetail?.cancelButton && this.form?.store_status_id && this.form?.store_status_id?.toLowerCase() !== 'cancelado'
    },

    orderId() {
      return this.$route.params.id
    },

    subHeaderActions() {
      return [
        'import', 'export',
        ...(this.orderId ? ['addnew-order'] : [])]
    },
    documentType(){
      return [
        'required',
        this.form.docType.name.toLowerCase() !== 'cnh' ? this.form.docType.name.toLowerCase() : 'number']
    },
    docTypeOptions(){
      return this.hasShipping ? [{ id: 1, name: 'CPF'},{ id: 2, name: 'CNPJ'}] : [{ id: 0, name: 'RG'},{ id: 1, name: 'CPF'},{ id: 2, name: 'CNPJ'},{ id: 3, name: 'CNH'}]
    },
    hasPaymentLink() {
      return this.form.link !== ''
    },
    hasValueInvoicePermission(){
      return !!this.permissions?.ordersDetail?.orderInvoiceValueField
    },
    hasShipping(){
      return this.form.order_type
    },

    customerCss() {
      const line = this.customer ?
        '"store_id store_id store_id transporter_id transporter_id transporter_id order_type order_type order_type . . .  "'
        :
        '"store_id store_id store_id transporter_id transporter_id transporter_id . . . . . ."'

      return {'--second-line': line}
    },

    hasDeliveryWindow() {
      return !!this.form.deliveryWindow[0] && !!this.form.deliveryWindow[1]
    },

    hasCollectWindow() {
      return !!this.form.collectWindow
    },

    isEdit() {
      return !!this.orderId
    },

    deliveryWindowCss() {
      const line = this.hasDeliveryWindow ?
        `"labelDeliveryWindow labelDeliveryWindow . . . . . . . . . ."
        "deliveryWindowInit deliveryWindowInit deliveryWindowInit deliveryWindowInit update . . . . . . ."`
        :
        '"empty empty empty empty empty empty empty empty empty empty empty empty"'

      return {'--deliveryWindow-line': line}
    },

    collectWindowCss() {
      const line = this.hasCollectWindow ?
        `"labelCollectWindow labelCollectWindow . . . . . . . . . ."
        "collectWindow collectWindow collectWindow . . . . . . . . ."`
        :
        '"empty empty empty empty empty empty empty empty empty empty empty empty"'

      return {'--collectWindow-line': line}
    },

    productGrid(){
      const lastColls = this.hasShipping ? 'productPrice productPrice' : 'productSize productSize'
      const productButton = !this.orderId ? 'productButton productButton' : lastColls

      const grid = this.hasShipping ?
        `"productName productName productName productQtd productWeight productSize productSize productSize productPrice productPrice ${productButton}"`
        :
        `"productName productName productName productName productWeight productWeight productWeight productSize productSize productSize ${productButton}"`

      return {'--product-grid': grid}
    },

    sumProducts() {
      return this.form.products.reduce((initialValue, product) =>{
        let price = product.unity_price
          ? this.$options.filters.moneyToNumber(product.unity_price) * product.quantity
          : 0
        return initialValue + price
      }, 0)
    },

    farePercentage() {
      return this.sumProducts * parseFloat(this.fareObj.fare_percentage)
    },

    fareValue() {
      return Math.round((this.sumProducts + this.farePercentage) - this.sumProducts)
    },

    isMaster() {
      return JSON.parse(localStorage.getItem('user'))?.roles?.[0]?.name === 'master'
    },

    todayParsed() {
      return new Date().setHours(0, 0, 0, 0)
    }
  },

  async mounted() {
    if (this.orderId) {
      await this.fetchOrder()
      this.fetchfiles()
      this.fetchOrderPackages(this.orderId)
    } else {
      this.fetchProductsSizes()
      this.addProduct()
    }
    this.fetchTransporters()
  },
  methods: {
    blueprint,
    ...mapActions({
      fetchStores: 'stores/fetchStores',
      fetchOrderPackages: 'orders/fetchOrderPackages',
      setLoading: 'loading/setLoading',
      fetchProductsSizes: 'orders/fetchProductsSizes',
      fetchTransporters: 'transporters/fetchTransporters',
      fetchAttachments: 'file/fetchAttachments',
      deleteAttachments: 'file/deleteAttachments',
      clearAttachments: 'file/clearAttachments'
    }),

    getProductWeight(p) {
      return p.weight
        ? `${p.weight} kg`
        : '-'
    },

    getProductSize(p) {
      return (p.width && p['length'] && p.height)
        ? `${p.width} x ${p['length']} x ${p.height} m`
        : '-'
    },

    getProductVolume(p) {
      return p.volume
        ? `${p.volume} m³`
        : '-'
    },

    addProduct() {
      const product = {
        name: '',
        weight: '',
        size: null,
        quantity: '',
        unity_price: ''
      }
      this.form.products.push(product)
    },

    parseMoney(){
      this.total = this.sumProducts
      this.fare = this.fareValue > this.fareObj.fare_min ? this.fareValue : this.fareObj.fare_min
    },

    removeProduct(i) {
      this.form.products.splice(i,1)
      this.parseMoney()
    },

    setAddress(data) {
      this.form = {...this.form, ...data}
    },
    resetDocField() {
      this.form.document = ''
    },
    async fetchStoreAddress(data) {
      if (!this.isEdit) {
        await address.fetchStoreAddress({ id: data.id }, (_address) => {
          this.customer = _address.has_c2c
          this.fareObj = _address.parameters
          if(!this.customer) this.form.order_type = false
          for (let i in _address) {
            this.form[`origin_${i}`] = _address[i] || ''
          }
        }, (e) => e)
      }
    },
    async fetchOrder() {
      this.setLoading(true)
      await orders.fetchOrder({ id: this.orderId }, (res) => {
        const lastStatus = res.last_status_history ? res.last_status_history.status.type.name : undefined
        const showDate = lastStatus === 'entregue' || lastStatus === 'ocorrencia'
        const docType = res.info && res.info.type && res.info.type.name === 'c2c' ? {
          name: res.info.client.documents.type_document_client
        } : {
          id: 1,
          name: 'CPF'
        }
        res = {
          ...res,
          ...res.estimated_receiver,
          ...res.order_addresses_id,
          transporter: res.transporter_id,
          driver_id: res.driver_id ? res.driver_id.name : '',
          store_status_id: res.store_status_id ? res.store_status_id.name : {},
          created_at: showDate ? res.last_status_history.created_at : '',
          order_receiver: res.receiver ? res.receiver.name : '',
          order_receiver_doc: res.receiver ? res.receiver.document: '',
          order_comments: res.last_status_history && res.last_status_history.observations ? res.last_status_history.observations : '',
          docType: docType,
          link: res.last_pagar_me ? res.last_pagar_me.link : '',
          products: res.products,
          collectWindow: res.schedule?.schedule_date,
          phones: res.phones,
          volume: res.dimension?.volume,
          cubed_weight: res.dimension?.cubed_weight,
          invoice_value: res.invoice?.value || 0,
          is_heavy: res.dimension?.is_heavy || 0
        }
        if (res.info) {
          this.customer = res.info.order_type
          res.order_type = res.info.order_type
        }

        for (let i in this.form) {
          if (res[i]) this.form[i] = res[i]
        }

        if (res.deliveryWindowInit && res.deliveryWindowEnd){
          this.form.deliveryWindow.push(res.deliveryWindowInit)
          this.form.deliveryWindow.push(res.deliveryWindowEnd)
        }

        this.fareObj = res.store.fare
        this.parseMoney()
      }, (e) => e, () => this.setLoading(false))
    },

    checkProducts(){
      if (this.hasShipping) {
        this.validProducts = []
        this.form.products.filter((product) =>{
          for (const key in product) {
            this.productsRules[key] = !Rules.required(key, product[key]).valid
            this.validProducts.push(!this.productsRules[key])
          }
        })
      }
    },

    async onSubmit(data) {
      if (this.hasShipping && this.validProducts.includes(false)) return

      data.set('order_type', data.get('order_type') === 'false' ? 'delivery' : 'c2c')

      if (!this.orderId) {
        data.delete('collect_zip')
        data.delete('collect_address')
        data.delete('collect_number')
        data.delete('collect_complement')
        data.delete('collect_neighborhood')
        data.delete('collect_city')
        data.delete('collect_state_id')
        data.delete('created_at')
      }

      this.setLoading(true)
      await orders.createOrders(data, () => this.$router.push('/orders'), (e) => this.endpointErrors = e.validation, () => this.setLoading(false))
    },

    async update() {
      const data = {
        store_id: this.form.store_id?.id,
        deliveryWindowInit: this.form.deliveryWindow[0],
        deliveryWindowEnd: this.form.deliveryWindow[1]
      }
      await orders.updateOrders(this.orderId, data, () => this.$refs.DialogOrders?.handleUpdate(), (e) => this.endpointErrors = e.validation, () => this.setLoading(false))
    },

    confirmCancelOrder() {
      this.$refs.DialogOrders?.handleCancel()
    },

    async cancelOrder() {
      this.setLoading(true)
      return await orders.cancelOrder(
        { order_id: this.orderId, store_id: this.form?.store_id?.id },
        () => this.$router.push('/orders'),
        e => this.endpointErrors = e.validation,
        () =>  this.setLoading(false)
      )
    },

    cancelDialog(){
      this.showConfirmDialog = false
    },

    fetchfiles() {
      this.clearAttachments().then(() => this.fetchAttachment())
    },

    async uploadOrdersFiles(data) {
      this.setLoading(true)
      const formData = new FormData()
      const attachment = data.map(el => el.file)

      for (let i in attachment) {
        formData.append('files[]', attachment[i])
      }

      const payload = {
        id: this.orderId,
        files: formData
      }
      await file.postFiles(payload, async () => {
        await this.fetchAttachment()
      }, (e) => this.$refs.DialogOrders?.handleErros(e), () => this.setLoading(false))
    },

    async fetchAttachment() {
      await this.fetchAttachments(this.orderId)
    },

    async removeFile(data) {
      await this.deleteAttachments(data).then(async () => await this.fetchAttachment())
    }
  }
}
</script>

<style lang="scss" scoped>
.OrdersForm {
  &__content {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: ". main .";
    grid-column-gap: 1rem;
  }

  &__form {
    grid-template-areas:
      "labelDetails labelDetails . . . . . . . . . ."
      var(--second-line)
      "order_number order_number order_number invoice_number invoice_number invoice_value invoice_value . . . . ."
      "files_label files_label . . . . . . . . . ."
      "files_input files_input files_input files_input files_input files_input files_input files_input files_input files_input files_input files_input"
      "link link link link link . . . . . . ."
      "labelPerson labelPerson . . . . . . . . . ."
      "name name name phone phone email email email docType document document document"
      var(--deliveryWindow-line)
      var(--collectWindow-line)
      "labelDelivery labelDelivery . . . . . . . . . ."
      "driver_id driver_id driver_id  store_status_id store_status_id store_status_id store_status_id created_at created_at . . . "
      "order_receiver order_receiver order_receiver order_receiver_doc order_receiver_doc order_receiver_doc order_comments order_comments order_comments order_comments . ."
      "labelCollect labelCollect . . . . . . . . . ."
      "collect_address collect_address collect_address collect_address collect_address collect_address collect_address collect_address collect_address collect_address collect_address collect_address"
      "labelOrigin labelOrigin . . . . . . . . . ."
      "origin_address origin_address origin_address origin_address origin_address origin_address origin_address origin_address origin_address origin_address origin_address origin_address"
      "labelDestiny labelDestiny . . . . . . . . . ."
      "destiny_address destiny_address destiny_address destiny_address destiny_address destiny_address destiny_address destiny_address destiny_address destiny_address destiny_address destiny_address"
      "labelphones labelphones . . . . . . . . . ."
      "phones phones phones phones phones phones phones phones phones phones phones phones"
      "labelProducts labelProducts labelProducts productsVolume productsVolume productsVolume productsVolume productsVolume productsVolume productsVolume productsVolume productsVolume"
      "products products products products products products products products products products products products"
      "productShipping productShipping productShipping productShipping productShipping productShipping productShipping . . . confirm confirm";
    margin-bottom: 40px;
  }

  &__labelphones { grid-area: labelphones; }
  &__phones {
    grid-area: phones;
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
  }
  &__phone {
    width: 140px;
  }

  &__labelProducts {
    grid-area: labelProducts;
    transform: initial !important
  }
  &__productsVolume {
    grid-area: productsVolume;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &__wrapper {
      color: $color-primary-base;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: $spacing-inset-1xsmall;

      &--heavy {
        background: $color-blue-800;
        color: #fff;
        padding: $spacing-squished-inset-small;
        border-radius: $border-radius-pill;
      }

      @media #{$mobile-view} {
        justify-content: flex-start;
      }
    }
  }
  &__products {
    grid-area: products;
    width: 100%;
    @media (max-width: $viewport-sm) {
      display: table;
    }
  }
  &__product {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    margin-bottom: 10px;
    grid-column-gap: 20px;
    align-items: end;
    grid-template-areas: var(--product-grid);
    @media (max-width: $viewport-sm) {
      display: block;
      width: 100%;
    }
  }

  &__productName { grid-area: productName; }
  &__productQtd { grid-area: productQtd; }
  &__productPrice { grid-area: productPrice; }
  &__productWeight{ grid-area: productWeight; }
  &__productSize{ grid-area: productSize; }
  &__shipping{
    grid-area: productShipping;
    align-self: center;
    font-size: $font-size-medium;
  }
  &__productButton{
    grid-area: productButton;
    @media #{$mobile-view} {
      width: 100%;
      margin: 1rem 0 0 0;
      padding: 0;
    }
  }
}
</style>
<style lang="scss">
.OrdersForm {
  .DynamicForm {
    @media #{$mobile-view} {
      display: flex;
      flex-direction: column;
      gap: 0;

      &__confirm {
        order: 1;
      }
    }
  }
}
</style>
