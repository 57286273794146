export default ({ isEdit, hasPaymentLink, hasValueInvoicePermission, docTypeOptions, documentType, customer, transporterPermission, transporters, hasDeliveryWindow, hasCollectWindow, attachments, isMaster, todayParsed}) => {

  //Order Details
  const orderDetails = [
    {
      id: 'labelDetails',
      tag: 'label',
      content: 'Detalhes do Pedido'
    },
    {
      id: 'store_id',
      model: 'store_id',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Vincular Lojistas',
        label: 'Vincular Lojistas',
        placeholder: 'Pesquisar Lojistas',
        suggestions: 'searchStores',
        searchPromptText: 'Nome do Lojista',
        invalidLabel: 'Selecione um lojista',
        readOnly: isEdit,
      },
      events: {
        input: 'selectStore'
      }
    },
    {
      id: 'transporter_id',
      model: 'transporter_id',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Vincular Transportadora',
        label: 'Vincular Transportadora',
        placeholder: 'Selecione',
        selectOptions: transporters,
        invalidLabel: 'Vincule ao menos uma transportadora',
        readOnly: isEdit || !transporterPermission,
      },
    },
    {
      id: 'order_number',
      model: 'order_number',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Pedido',
        placeholder: 'Número do pedido',
        invalidLabel: 'Insira um número do pedido',
        readOnly: isEdit
      }
    },
    ...(isEdit && hasValueInvoicePermission ? [{
      id: 'invoice_value',
      model: 'invoice_value',
      tag: 'InputGroup',
      props: {
        label: 'Valor da Nota Fiscal',
        mask: 'money',
        readOnly: true
      }
    }] : []),
    {
      id: 'invoice_number',
      model: 'invoice_number',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Nota Fiscal',
        placeholder: 'Nota Fiscal',
        invalidLabel: 'Insira uma nota fiscal',
        readOnly: isEdit
      }
    },

    ...(isEdit ? [{
      id: 'files_label',
      tag: 'label',
      content: 'Anexos do Pedido'
    },
    {
      id: 'files_input',
      tag: 'BaseInputFileGroup',
      props: {
        files: attachments,
        multiple: true,
        accept: ['.jpg', '.png', '.xlsx', '.xls', '.ppt', '.pptx', '.pdf'],
        canRemoveFile: isMaster
      },
      events: {
        postFiles: 'postFiles',
        removeFile: 'removeFile'
      }
    }] : []),

    ...(isEdit && hasPaymentLink ? [{
      id: 'link',
      model: 'link',
      tag: 'Link',
      props: {
        label: 'Link de Pagamento',
        target: '_blank'
      }
    }] : []),
  ]

  //Customer
  const customerCheckBox = [
    {
      id: 'order_type',
      model: 'order_type',
      tag: 'BaseCheckBox',
      style: 'align-self: end',
      props:{
        name: 'Frete pelo Cliente',
        disabled: isEdit
      }
    }
  ]

  //Order Receiver
  const orederReceiver = [
    {
      id: 'labelPerson',
      tag: 'label',
      content: 'Destinatário'
    },
    {
      id: 'name',
      model: 'name',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Nome',
        placeholder: 'Nome',
        invalidLabel: 'Insira um nome',
        readOnly: isEdit
      }
    },
    {
      id: 'phone',
      model: 'phone',
      tag: 'InputGroup',
      rules: ['required', 'phone'],
      props: {
        label: 'Celular',
        placeholder: 'Número de celular',
        invalidLabel: 'Insira um número de celular',
        mask: 'phone',
        readOnly: isEdit
      }
    },
    {
      id: 'email',
      model: 'email',
      tag: 'InputGroup',
      rules: ['required', 'email'],
      props: {
        label: 'E-mail',
        placeholder: 'E-mail',
        invalidLabel: 'E-mail inválido',
        readOnly: isEdit,
      }
    },
    {
      id: 'docType',
      model: 'docType',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Tipo',
        label: 'Tipo',
        placeholder: 'Selecione',
        selectOptions: docTypeOptions,
        invalidLabel: 'Vincule ao menos um Tipo',
        clearable: false,
        readOnly: isEdit,
      },
      events: {
        input: 'resetDocField'
      }
    },
    {
      id: 'document',
      model: 'document',
      tag: 'InputGroup',
      rules: documentType,
      props: {
        label: 'Documento',
        placeholder: 'Documento',
        invalidLabel: 'Documento inválido',
        mask: documentType[1],
        readOnly: isEdit
      }
    },
  ]

  //Delivery Window
  const deliveryWindow = [
    {
      id: 'labelDeliveryWindow',
      tag: 'label',
      content: 'Janela de Entrega'
    },
    {
      id: 'deliveryWindowInit',
      model: 'deliveryWindow',
      tag: 'BaseDatePicker',
      props: {
        label: 'Data de entrega',
        format: 'DD/MM/YYYY HH:mm',
        type: 'datetime',
        range: true,
        clearable: false,
        notBefore: todayParsed,
      }
    },
    {
      id: 'update',
      tag: 'BaseButton',
      style: 'margin: 25px 0 0 0;',
      props: {
        label: 'Atualizar janela de entrega',
        color: 'primary-base',
        textColor: 'white',
        filled: true
      },
      events: {
        click: 'updateDeliveryWindow'
      }
    }
  ]

  //Collect Window
  const collectWindow = [
    {
      id: 'labelCollectWindow',
      tag: 'label',
      content: 'Janela de Coleta'
    },
    {
      id: 'collectWindow',
      model: 'collectWindow',
      tag: 'BaseDatePicker',
      props: {
        label: ' ',
        type: 'datetime',
        range: false,
        readOnly: true
      }
    },
  ]

  //Delivery Details
  const deliveryDetails = [
    {
      id: 'labelDelivery',
      tag: 'label',
      content: 'Detalhes da Entrega'
    },
    {
      id: 'driver_id',
      model: 'driver_id',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Motorista',
        placeholder: '',
        readOnly: isEdit
      }
    },
    {
      id: 'store_status_id',
      model: 'store_status_id',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Status',
        placeholder: '',
        readOnly: isEdit
      }
    },
    {
      id: 'created_at',
      model: 'created_at',
      tag: 'BaseDatePicker',
      rules: ['required'],
      props: {
        label: 'Data da Entrega',
        placeholder: '',
        format: 'DD/MM/YYYY HH:mm',
        type: 'date',
        readOnly: isEdit,
        range: false,
        prependIcon: false,
        clearable: false,

      }
    },
    {
      id: 'order_receiver',
      model: 'order_receiver',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Recebedor',
        placeholder: '',
        readOnly: isEdit
      }
    },
    {
      id: 'order_receiver_doc',
      model: 'order_receiver_doc',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Documento do Recebedor',
        placeholder: '',
        readOnly: isEdit
      }
    },
    {
      id: 'order_comments',
      model: 'order_comments',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Observação',
        placeholder: '',
        readOnly: isEdit
      }
    },
  ]

  //Collect Address
  const collectAddress = [
    {
      id: 'labelCollect',
      tag: 'label',
      content: 'Endereço de coleta'
    },
    {
      id: 'collect_address',
      tag: 'InputAddress',
      props: {
        disabled: true,
        fields: {
          zip: 'collect_zip',
          address: 'collect_address',
          number: 'collect_number',
          complement: 'collect_complement',
          neighborhood: 'collect_neighborhood',
          city: 'collect_city',
          state_id: 'collect_state_id'
        }
      }
    },
  ]

  //Origin Address
  const originAddress = [
    {
      id: 'labelOrigin',
      tag: 'label',
      content: 'Endereço do CD'
    },
    {
      id: 'origin_address',
      tag: 'InputAddress',
      props: {
        disabled: true,
        fields: {
          zip: 'origin_zip',
          address: 'origin_address',
          number: 'origin_number',
          complement: 'origin_complement',
          neighborhood: 'origin_neighborhood',
          city: 'origin_city',
          state_id: 'origin_state_id'
        }
      }
    },
  ]

  //Destiny Address
  const destinyAddress = [
    {
      id: 'labelDestiny',
      tag: 'label',
      content: 'Endereço de entrega'
    },
    {
      id: 'destiny_address',
      tag: 'InputAddress',
      props: {
        disabled: isEdit,
        fields: {
          zip: 'destiny_zip',
          address: 'destiny_address',
          number: 'destiny_number',
          complement: 'destiny_complement',
          neighborhood: 'destiny_neighborhood',
          city: 'destiny_city',
          state_id: 'destiny_state_id'
        }
      }
    },
  ]

  //Actions
  const submit = [
    {
      id: 'confirm',
      tag: 'BaseButton',
      props: {
        label: 'ENVIAR PEDIDO',
        theme: 'icon-left',
        color: 'primary-base',
        textColor: 'white',
        icon: 'package-request',
        type: 'submit',
        filled: true
      }
    }
  ]

  return [
    ...orderDetails,
    ...(customer ? customerCheckBox : []),
    ...orederReceiver,
    ...(isEdit && hasDeliveryWindow ? deliveryWindow : []),
    ...(isEdit && hasCollectWindow ? collectWindow : []),
    ...(isEdit ? deliveryDetails : []),
    ...collectAddress,
    ...originAddress,
    ...destinyAddress,
    ...(!isEdit ? submit : []),
  ]
}