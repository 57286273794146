<template>
  <Dialog
    v-if="show"
    :warning="message"
    :label-cancel="labelCancel"
    label-confirm="Confirmar"
    @confirm="handlerConfirm"
    @cancel="handlerClose"
  />
</template>
<script>
import { Dialog } from '@/components/molecules'
export default {
  name: 'DialogOrders',
  components: {
    Dialog,
  },
  data() {
    return {
      show: false,
      message: '',
      trigger: undefined,
      labelCancel: 'Não'
    }
  },
  methods: {
    async handlerConfirm() {
      switch (this.trigger) {
      case 'update':
        this.$emit('update')
        this.$router.push('/orders')
        break
      case 'create':
        this.$emit('create')
        break
      case 'cancel':
        this.$emit('cancel')
        break
      case 'errors':
        this.$emit('errors')
        break
      default:
        break
      }

      this.show = false
    },

    //Handlers
    handlerOpen() {
      this.show = true
    },

    handlerClose() {
      this.show = false
    },

    handleUpdate() {
      this.labelCancel = null
      this.handlerOpen()
      this.message = 'Janela de entrega atualizada com sucesso!'
      this.trigger = 'update'
    },

    handleCreate() {
      this.handlerOpen()
      this.message = 'Pedido criado com sucesso!'
      this.trigger = 'create'
    },

    handleCancel() {
      this.handlerOpen()
      this.message = 'Tem certeza que deseja cancelar esse pedido?'
      this.trigger = 'cancel'
    },

    handleErros() {
      this.labelCancel = null
      this.handlerOpen()
      this.message = 'Ocorreu um erro ao realizar upload do(s) arquivo(s), verifique se os formatos estão corretos e tente novamente'
      this.trigger = 'errors'
    }
  },
}
</script>